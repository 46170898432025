<template>
    <div class="card" style="margin-bottom: 20px;">
        <div v-if="!loading && selectedTopic">
            <DataTable 
                :value="allPrograms" 
                :loading="loading" 
                responsiveLayout="scroll" 
                showGridlines
                :paginator="true" 
                
                :rows="rowsPagValue" 
                :rowsPerPageOptions="[10, 20, 50]" 
                :rowHover="true" 
                :first="firstPagValue"
                
                removableSort
                dataKey="documentProgramId"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                v-model:filters="filters"
                filterDisplay="menu"
                :sort-field="sortFieldValue" 
                :sort-order="sortOrderValue" 
                @sort="onSort($event)"
                @page="onSort($event)"
            >
                <!-- :lazy="true"
                :totalRecords="totalRecords" -->
                <template #header>
                    <div style="display: flex; align-items: center;">
                        <h5 style="margin-bottom: 0;">Programmes for: <strong> {{ selectedTopic.name }} </strong></h5>
                        <Button 
                            class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                            icon="pi pi-question-circle" 
                            @click="goToHeaderPage('programOverview-header')" >
                        </Button>
                    </div>


                    <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                        <!-- <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start"> -->
                        <div style="display: flex; justify-content: flex-start; width: 15%;">
                            <Button 
                                type="button"
                                icon="pi pi-plus"
                                label="Add Programme"
                                class="p-button-outlined"
                                @click="openCreateProgram()" 
                            />
                        </div>
                        <!-- showSegmentation Clear Button, Filters , keywordSearch  -->
                        <!-- <div class="col-sm-12 col-md-8"> -->
                        <div style="display: flex; justify-content: flex-end; width: 85%; gap: 1rem;">
                            <div style="display: flex; justify-content: center; align-items: center; gap: 1rem;">
                                <label > show Segmentations</label>
                                <InputSwitch v-model="showSegmentation" />
                            </div>

                            <!-- add Button -->
                            <div class="d-flex flex-row justify-content-md-end">
                            <!-- <div style="display: flex; justify-content: flex-end; align-items: center;"> -->
                                <Button 
                                    type="button" 
                                    icon="pi pi-filter-slash" 
                                    label="Clear"
                                    class="p-button-outlined me-4" 
                                    @click="clearFilter()" 
                                    style="width: 6rem;"
                                />
                                <Dropdown
                                    v-model="filterProgramStatus"
                                    :options="programStatusOptions"
                                    optionLabel="status"
                                    optionGroupLabel="label"
                                    optionGroupChildren = "items"
                                    optionValue="status"
                                    placeholder="Filter Status"
                                    :showClear="true"
                                    class="me-4"
                                    style="width: 25%;"
                                >
                                <template #optiongroup="slotProps">
                                    <div v-if="!slotProps.option.label" class="flex align-items-center">
                                        <hr>
                                    </div>
                                    <div v-else>
                                    </div>
                                </template>

                                </Dropdown>

                                <span class="p-input-icon-left" style="position: relative;">
                                    <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                                    <InputText 
                                        v-model="filters['global'].value" 
                                        placeholder="Keyword Search" 
                                        class="keywordSearch" 
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- ProgramName -->
                <Column 
                    :field="'name'" 
                    :header="'Title'" 
                    :key="'name'" 
                    :sortable="true"
                >
                    <template #body="slotProps">
                        <div 
                            @click="openEditProgramDialog(slotProps.data)"
                            :style="{ width: '100%', height: '100%', cursor: 'pointer' }">
                            {{ slotProps.data.name }}
                        </div>
                    </template>
                </Column>

                <!-- Date Added -->
                <Column
                    :header="'Date Updated'"
                    :field="'dateAdd'"
                    :sortable="true"
                >
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.documentProgramTopics[0].programDate) }}
                        </div>
                    </template>
                </Column>
                <!-- segmentations -->
                <div 
                    v-for="(segmentation) in selectedTopic.segmentations.sort((a,b)=> a.order - b.order)" 
                    :key="segmentation.segmentationId"
                >
                    <div v-if="segmentation.isRegional">
                        <Column
                            :header="'Country'"
                            :key="segmentation.segmentationId"
                        >
                            <template #body="slotProps">
                                <div v-if="showSegmentation">
                                    <div 
                                        v-if ="slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0]"
                                    >
                                        {{getCountryName(slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0].regionCountry.countryId)  }}
                                    </div>
                                    <div v-else style="color: red;">
                                        segmentationItem Not Found
                                    </div>
                                </div>
                                <div v-else>
                                    <div>
                                        ---
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </div>
                    <div v-else>
                        <Column
                            :header="segmentation.name"
                            :key="segmentation.segmentationId"
                        >
                            <template #body="slotProps"> 
                                <div v-if="showSegmentation">
                                    <div v-if="slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0]">
                                        {{ slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationItem.segmentationId === segmentation.segmentationId )[0].segmentationItem.name  }}
                                    </div>
                                    <div v-else style="color: red;">
                                        segmentationItem Not Found
                                    </div>
                                </div>
                                <div v-else>
                                    <div>
                                        ---
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </div>
                    
                </div>
                <!-- DocumentCount -->
                <Column
                    :header="'Document count'"
                    :key="'Document count'"
                    bodyStyle="text-align: right"
                    style="width: 5rem;"
                >
                    <template #body="slotProps">
                        {{slotProps.data.totalDocuments }}
                    </template>
                </Column>

                
                <!-- Status  -->
                <Column
                    :header="'Status'"
                    :field="'status'"
                    :sortable="true"
                >
                    <!-- <template #body="slotProps">
                        <div>
                            {{ getProgramStatusName(slotProps.data.documentProgramStatusId)  }}
                        </div>
                    </template> -->
                </Column>

                <!-- checkbox -->
                <Column
                    :key="'documentProgramId'"
                    style="display: flex;
                        justify-content: center;
                    "
                >
                    <template #body="slotProps">
                        <Checkbox 
                            v-model="programCheck" 
                            :inputId="slotProps.data.documentProgramTopics[0].documentProgramTopicId" 
                            :value="slotProps.data.documentProgramTopics[0].documentProgramTopicId" 
                        />
                    </template>
                </Column>
                
                <template #paginatorstart>
                    <Button 
                        type="button" 
                        icon="pi pi-refresh" 
                        class="p-button-text"
                        @click="obtainDocumentsByTopicId()" />
                </template>
                <template #footer >
                    <div style="display:grid; place-items: center; margin-bottom: 1rem;">
                            <Button type="button" label="Go to all Topic Programmes"  class="p-button-info"
                            @click="openAllProgramDialog()" />
                    </div>
                    <div style="display:grid; place-items: center;" v-if="programCheck.length > 0 ">
                            <Button type="button" label="Edit Multiple Programmes"  class="p-button-info"
                            @click="openEditMultiplePrograms()" />
                    </div>
                    <div style="display:grid; place-items: center;" v-else>
                            <Button type="button" label="Edit Multiple Programmes"  class="p-button-info"
                            @click="openEditMultiplePrograms()" disabled />
                    </div>
                </template>

            </DataTable>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import formatToGlobalDate from '../../../../../../shared/helpers/date.helper';
import redirectHelperPage from '../../../../../../shared/helpers/redirectToHelperPage';
// import { useToast } from 'vue-toastification';
// const toast = useToast();

export default {
    name: 'DocumentProgramList',
    components: {
        ProgressSpinner,
        DataTable,
        Button,
        InputText,
        Column,
        Checkbox,
        Dropdown,
        InputSwitch,
    },
    data() {
        return {
            filters:null,
            loading: true,
            programCheck: [],
            //Sort
            sortFieldValue:null,
            sortOrderValue: null,
            // Pag
            firstPagValue: null,
            rowsPagValue: 10,
            //filters
            filterProgramStatus: null, 

            // TODO ON PROGRESS lazy 
            // lazyParams:{},
            // totalRecords: null,
            
            //showSegmentations
            showSegmentation: false,
        }
    },

    async created() {
        this.initFilters();
    },
    async mounted() {
        await this.getCountries();
        await this.obtainProgramStatus();
        await this.obtainProgramsByTopicId();

        this.cleanDocumentProgramTopicIds();
        this.setTopicProgramSort();
    },
    computed: {
        allPrograms() {
            let programs = this.$store.getters.obtainPrograms
            programs.map(program => {
                program.dateAdd = program.documentProgramTopics[0].programDate;
                program.status = this.getProgramStatusName(program.documentProgramTopics[0].documentProgramStatusId) ; 

            });
            programs.sort(function(a, b){
                return new Date(b.dateAdd) - new Date(a.dateAdd)
            })

            if(this.filterProgramStatus){
                if(this.filterProgramStatus === "Status not set"){
                    programs = programs.filter(program => program.status === "")
                }
                else{
                    programs = programs.filter(program => program.status === this.filterProgramStatus );
                }
            }

            return programs ;
        },
        documents(){
            return this.$store.getters.obtainDocuments;
        },
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        countries(){
           return this.$store.getters.obtainCountries;
        },

        programStatus(){
            return  this.$store.getters.obtainProgramStatus;
        },
        programStatusOptions(){
            const status = this.$store.getters.obtainProgramStatus;
            const statusFIlterOptions = [
                {
                    label: 'Status',
                    items: status
                },
                {
                    label: null, 
                    items: [
                        {
                            "documentProgramStatusId": "StatusNotSet",
                            "status": "Status not set",
                        }
                    ]
                }

            ]
            return statusFIlterOptions
        },

        topicProgramSort(){
            const programSortTopic =this.$store.getters.obtainTopicProgramSort 
            
            try {
                if(this.selectedTopic.topicId === programSortTopic.topicId){
                    return programSortTopic
                }
                else{
                    const programSortFromTopic = {
                        sortField: null,
                        sortOrder: null,
                        firstPagValue: null,
                        rowsPagValue: null,

                        //filters
                        status: null,
                    }
                    return programSortFromTopic
                }
                
            } catch (error) {
                const programSortFromTopic = {
                    sortField: null,
                    sortOrder: null,
                    firstPagValue: null,
                    rowsPagValue: null,
                    //filters
                    status: null,
                }
                return programSortFromTopic
            }
            // return this.$store.getters.obtainTopicProgramSort;
        },
        
    },
    methods: {
        goToHeaderPage(section){
            redirectHelperPage(section)
        },

        async getCountries() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCountries');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async obtainDocumentsByTopicId() {
            try {
                this.loading = true
                await this.$store.dispatch('obtainDocumentsByTopicId', {topicId: this.selectedTopic.topicId ,programId: undefined });
                this.loading = false
            }
            catch (error) {
                // console.error(error)
            }
        },
        async obtainProgramsByTopicId() {
            try {
                this.loading = true
                if(this.showSegmentation){
                    await this.$store.dispatch('obtainProgramsByTopicId', {topicId:this.selectedTopic.topicId , withDetails: true, withSegItems:true , totalDocuments: true } )
                }
                else{
                    // TODO change params endpoint
                    await this.$store.dispatch('obtainProgramsByTopicId', {topicId:this.selectedTopic.topicId , withDetails: false, withSegItems:false, totalDocuments: true } )
                }
                this.loading = false
            }
            catch (error) {
                // toast.error(error.message);
                this.loading = false
            }
        }, 
        async obtainProgramStatus(){
            try {
                this.loading= true;
                await this.$store.dispatch('obtainProgramStatus');
                this.loading = false;
            } catch (error) {
                this.loading = false; 
            }
        },


        setTopicProgramSort(){
            try {
                //sort
                this.sortFieldValue = this.topicProgramSort.sortField;
                this.sortOrderValue = this.topicProgramSort.sortOrder;

                //pag
                if(this.topicProgramSort.firstPagValue === null){
                  this.firstPagValue = 0;
                }
                else{
                    this.firstPagValue = this.topicProgramSort.firstPagValue;
                }
                if(this.topicProgramSort.rowsPagValue === null){
                    this.rowsPagValue = 10;
                }
                else{
                    this.rowsPagValue = this.topicProgramSort.rowsPagValue
                }

                //filters 
                this.filterProgramStatus = this.topicProgramSort.status;

            } catch (error) {
                console.log(error);
            }
        },

        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        programsIdByTopic() {
            let a = []
            let c = []
            this.documents.map(document => document.topics.map(topic => a.push(topic)))
            a.filter(item => item.topicId === this.selectedTopic.topicId)
                .map(program => {
                    const index = c.some(p => p === program.documentProgramId)
                    if (!index) {
                        c.push(program.documentProgramId)
                    }
                })
            return c
        },
        async openEditProgramDialog(event) {
            this.$router.push(`./program/overview/${event.documentProgramTopics[0].documentProgramTopicId}`);
        },
        async openAllProgramDialog(){
            this.$router.push(`../programs`);
        },
        getCountryName( countryId ){
            const a = this.countries.find(country => country.countryId=== countryId)
            return a.name 
        },
        formatDate(date) {
          return formatToGlobalDate(date);
        },

        getProgramStatusName(programStatusId ){
            const statusName =  this.programStatus.filter(stat => stat.documentProgramStatusId === programStatusId);
            if(statusName[0]){
                return statusName[0].status
            }
            else{
                return ''
            }
        },
        

        // Multiple Programs 
        cleanDocumentProgramTopicIds(){
            this.$store.dispatch('cleanDocumentProgramTopicIds')
        },
        // save programCheck to state and redirect
        openEditMultiplePrograms () { 
            this.cleanDocumentProgramTopicIds();
            this.$store.dispatch('addDocumentProgramTopicIds', this.programCheck )
            this.$router.push('../programs/edit');
        },

        // sortFunctions
        onSort(e){
            const topicProgramSort = {
                sortField: e.sortField,
                sortOrder: e.sortOrder,
                firstPagValue: e.first,
                rowsPagValue: e.rows,
                topicId:this.selectedTopic.topicId
            }
            this.$store.dispatch('updateTopicProgramSort', topicProgramSort)
        },
        onChangeFilters(){
            const filterDocProgStatus = {
                topicId: this.selectedTopic.topicId,
                status: this.filterProgramStatus,
            }

            this.$store.dispatch('updateTopicProgramSortFilters', filterDocProgStatus);
        },

        //Open add Program 
        openCreateProgram(){
            this.$router.push('/admin/topics/program/new')
        },
    },
    watch: {
        // 'selectedTopic': {
        //     async handler(newVal) {
        //         console.log(newVal)
        //         // await this.getSegmentations(newVal)
        //         await this.obtainDocumentsByTopicId()
        //     },
        //     deep: true,
        // },
        async selectedTopic(newValue) {
            if (newValue) {
                await this.obtainDocumentsByTopicId();
                await this.obtainProgramsByTopicId();
            }
        },
        filterProgramStatus(){
            this.onChangeFilters();
        },
        'showSegmentation':{
            async handler(){
                await this.obtainProgramsByTopicId();
            },
            deep:true,
        }
    }
}
</script>

<style scoped>
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>
