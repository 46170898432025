<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-4">
        <!-- DocumentList -->
        <DocumentsList />
        <!-- ProgramList  -->
        <ProgramsListVue />

      </div>
      <!-- <div class="col-12 py-4">
        <Button label="Programmes per Country Overview" class="p-button-info" @click="goProgramsPerCountry"></Button>
        <Button label="Contracts per Company Overview" class="p-button-info mx-3" @click="gotContractPerCompany"></Button>
      </div>
      <div class="col-12 py-4">
        <Button label="Export data" class="ml-3" style="background-color: blue;" @click="goOutput()" />
      </div> -->
    </div>
  </div>
</template>
<script>
import DocumentsList from './components/DocumentsList.vue';
import ProgramsListVue from './components/ProgramList.vue';
// import Button from 'primevue/button'

export default {
  name: 'Document',
  components: {
    DocumentsList,
    ProgramsListVue,
    // Button,
  },
  async created() {
    // await this.obtainDocumentsByTopicId()
  },
  computed: {
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    // documents(){
    //   return this.$store.getters.obtainDocuments;
    // }
  },
  methods: {
    goOutput() {
      this.$router.push(`./output/${this.selectedTopic.topicId}`);
    },
    goProgramsPerCountry() {
      this.$router.push(`./programpercountry/${this.selectedTopic.topicId}`);
    },
    gotContractPerCompany() {
      this.$router.push(`./contractpercompany/${this.selectedTopic.topicId}`);
    },
    // async obtainDocumentsByTopicId() {
    //   try {
    //     await this.$store.dispatch('obtainDocumentsByTopicId', this.selectedTopic.topicId);
    //   }
    //   catch (error) {
    //     console.error(error)
    //   }
    // },
  }
};
</script>

<style>
.data-grid-calculated-element {
  text-align: center;
  font-style: italic;
  padding-top: 10px;
}

.data-grid-column-total {
  font-style: italic;
  text-align: center;
}

.data-grid-form-control {
  border-radius: 0;
}

.row-is-active,
.column-is-active {
  background-color: #f0f0f0;
}

.data-set-sub-row {
  text-align: right;
}

.data-grid-column-total {
  padding-top: 10px;
  font-style: italic;
}

.bold-row-name {
  font-weight: bold;
}

.cagr-column-header span {
  cursor: pointer;
}

.cagr-based-on-incomplete-data {
  color: red;
}

.tab-panel {
  display: none;
}

.tab-panel.active {
  display: normal;
}

.data-set-row {
  padding-left: 5%;
  padding-right: 5%;
}

.data-set-row .row-title {
  padding-top: 10px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 1%;
}

.pl-2 {
  padding-left: 2%;
}

.pl-3 {
  padding-left: 3%;
}

.pl-4 {
  padding-left: 4%;
}

.pl-5 {
  padding-left: 5%;
}

.pl-6 {
  padding-left: 6%;
}

.pl-7 {
  padding-left: 7%;
}

.is-last-prediction {
  border-right: 4px solid #7b7b7b;
}

.datapoint-green {
  background-color: #d4edda;
}

.datapoint-red {
  background-color: #f8d7da;
}
</style>
