<template>
  <!-- DOCUMENTS FROM OVERVIEW -->
  <div class="card" v-if="selectedTopic" style="margin-bottom: 20px;">
    <DataTable 
      :value="documents" 
      :loading="loading" 
      responsiveLayout="scroll" 
      showGridlines 
      :paginator="true"
      
      :rows="rowsPagValue" 
      :rowsPerPageOptions="[10, 20, 50]" 
      :rowHover="true" 
      :first="firstPagValue"

      removableSort 
      dataKey="id"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
      v-model:filters="filters"
      filterDisplay="menu"
      
      :sort-field="sortFieldValue" 
      :sort-order="sortOrderValue" 
      @sort="onSort($event)"
      @page="onSort($event)"
    >
      <template #header>
        <div style="display: flex; align-items: center; " >
          <h5 style="margin-bottom: 0;" v-if="selectedTopic">
            Documents for: <strong> {{ selectedTopic.name }} </strong>
          </h5>
          <Button
            class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
            icon="pi pi-question-circle" 
            @click="goToHeaderPage('documentsOverview-header')"
          />
        </div>

        <div class="table-header row gy-3 d-flex flex-row justify-content-between">
          <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
            <Button 
              type="button" 
              icon="pi pi-plus" 
              label="Add Document" 
              class="p-button-outlined"
              @click="openAddDocument()" 
            />
          </div>

          <!-- Clear Button, FilterApplication and keywordSearch  -->
          <div class="col-sm-12 col-md-8">
            <div class="d-flex flex-row justify-content-md-end">
              <Button 
                type="button" 
                icon="pi pi-filter-slash" 
                label="Clear" 
                class="p-button-outlined me-4"
                @click="clearFilter()" 
              />

              <!-- DocumentTypeFilter -->
              <Dropdown
                v-model="filterDocumentSourceType"
                :options="documentSources"
                optionLabel="name"
                optionValue="documentSourceId"
                placeholder="Filter Document Type"
                :showClear="true"
                class="me-4"
                style="width:25%;"
              >
              </Dropdown>

              <!-- DocumentApplication Filter -->
              <!-- Hide Application feat usability 11-01-2024 -->
              <!-- <Dropdown
                v-model="filterApplication" 
                :options="documentApplicationsFilter" 
                optionLabel="name" 
                optionGroupLabel="label" 
                optionGroupChildren="items" 
                option-value="applicationTypeId"
                placeholder="Filter Application" 
                class="me-4"
                :showClear="true"
                style="width:25%;"
              >
                <template #optiongroup="slotProps">
                    <div v-if="!slotProps.option.label" class="flex align-items-center" >
                      <hr>
                    </div>
                    <div v-else  >
                    </div>
                </template>
              </Dropdown> -->

              <span class="p-input-icon-left" style="position: relative;">
                <i class="pi pi-search" style="position: absolute; left: 5%; top:50%"  />
                <InputText 
                  v-model="filters['global'].value" 
                  placeholder="Keyword Search" 
                  class="keywordSearch"
                />
              </span>
            </div>
          </div>
        </div>
      </template>
      <!-- title -->
      <Column 
        :field="'title'" 
        :header="'Title'" 
        :key="'title'" 
        :sortable="true"
        style="min-width: 30rem"
      >
        <template #body="slotProps">
          <div @click="openEditDocumentDialog(slotProps.data)"
            :style="{ width: '100%', height: '100%', cursor: 'pointer' }">
            {{ slotProps.data.title }}
          </div>
        </template>
      </Column>
      <!-- dateAdded -->
      <Column
        :field="'dateAdded'"
        :header="'Document Added'"
        :key="'dateAdded'"
        bodyStyle="text-align: center"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.dateAdded)  }}
        </template>
      </Column>
      <!-- document Date -->
      <Column 
        :field="'documentDate'" 
        :header="'Document Date'" 
        :key="'documentDate'" 
        bodyStyle="text-align: center"
      >
        <template #body="slotProps">
          {{ formatDate(slotProps.data.documentDate) }}
        </template>
      </Column>
      <!-- sourcerName -->
      <Column
        :field="'documentSourceTypeId'"
        :header="'Document Type'"
        :key="'documentSourceTypeId'"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ getDocumentSourceTypeName(slotProps.data.documentSourceTypeId)  }}
        </template>
      </Column>
      <!-- program name -->
      <Column
        :header="'Programme'"
      >
        <template #body="slotProps">
          <!-- {{ slotProps.data.documentTopics.map(docprogramID =>docprogramID.documentProgramId ) }} -->
          {{ getProgramName(slotProps.data.documentTopics) }}
          <!-- <div v-if="slotProps.data.documentTopics" >
            {{ 
              (slotProps.data.documentTopics[0].documentProgramId)
              ? getProgramName( slotProps.data.documentTopics )
              : "no Program"
            }}
          </div>
          <div v-else>
            no Program
          </div> -->
        </template>
      </Column>
      <!-- Application -->
      <!-- Hide Application feat usability 11-01-2024 -->
      <!-- <Column 
        :field="'applications'" 
        :header="'Applications'" 
        :key="'applications'" 
        :sortable="false"
      >
        <template #body="slotProps">
          <div>
            <span 
              v-tooltip="application.name" 
              class="mx-2" 
              v-for="application of getApplicationNameById(
              slotProps.data.applications
              )" 
              :key="application.applicationTypeId"
            >
              <span v-html="application.icon"></span>
              <font-awesome-icon :icon="[application.prefix, application.iconName]" /> 
            </span>
          </div>
        </template>
      </Column> -->
      
      <!-- Countries -->
      <Column
        :field="'documentCountries'"
        :header="'Countries'"
        :key="'documentCountries'"
      >
        <template #body="slotProps">
          {{ getDocumentCountriesAbreviation(slotProps.data.documentCountries)}}
        </template>
      </Column>

      <!-- Companies  -->
      <Column
        :field="'documentCompanies'"
        :header="'Companies'"
        :key="'documentCompanies'"
      >
        <template #body="slotProps">
          {{ getDocumentCompaniesNameAbreviation(slotProps.data.documentCompanies)  }}
        </template>
      </Column>

      <!-- preview Button -->
      <Column 
        :exportable="false" 
        style="min-width: 5rem"
      >
        <template #body="slotProps">
          <div style="display:flex">
            <div>
              <div v-if="slotProps.data.text">
                <Button
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-success mx-2"
                  @click="openPreviewDocumentDialog(slotProps.data)"
                  @mouseenter="hoverPreviewDocumentDialogIn(slotProps.data)"
                  @mouseleave="hoverPreviewDocumentDialogOut"
                />
              </div>
              <div v-else>
                <Button
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-success mx-2"
                  :disabled="true"
                />
              </div>
            </div>

            <div>
              <div v-if="slotProps.data.contract">
                <Button
                  icon="pi pi-file"
                  class="p-button-rounded p-button-success mx-2"
                  @click="openPreviewDocumentContracDialig(slotProps.data)"
                  @mouseenter="hoverPreviewDocumentContractDialogIn(slotProps.data)"
                  @mouseleave="hoverPreviewDocumentContractDialogOut"
                >
                  <font-awesome-icon icon="fa-solid fa-file-contract" />
                </Button>
              </div>
              <div v-else>
                <Button
                  icon="pi pi-file"
                  class="p-button-rounded p-button-success mx-2"
                  :disabled="true"
                >
                  <font-awesome-icon icon="fa-solid fa-file-contract" />
                </Button>
              </div>
            </div>

            <div>
                <div v-if="slotProps.data.filePublicUrl">
                  <Button
                    icon="pi pi-file-pdf"
                    class="p-button-rounded p-button-success mx-2"
                    @click="openPreviewDocumentFileDialig(slotProps.data)"
                  >
                  </Button>
                </div>
                <div v-else>
                  <Button
                    icon="pi pi-file-pdf"
                    class="p-button-rounded p-button-success mx-2"
                    :disabled="true"
                  >
                  </Button>
                </div>

            </div>

          </div>
        </template>
      </Column>
      <!-- remove Button -->
      <Column 
        :exportable="false" 
        style="min-width: 5rem">
        <template #body="slotProps">
          <div style="display: grid; place-items: center;">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="openDeleteDocumentDialog(slotProps.data)" />
          </div>
        </template>
      </Column>

      <template #paginatorstart>
        <Button type="button" icon="pi pi-refresh" class="p-button-text" @click="obtainDocumentsByTopicId()" />
      </template>
      <template #footer>
        <div style="display:grid; place-items: center;">
            <Button 
              type="button" 
              label="Go to all Topic Documents" 
              class="p-button-info"
              @click="goToAllDocuments()" 
            />
        </div>
      </template>
    </DataTable>
  </div>

  <!-- add Document -->
  <Dialog
    v-model:visible="addDocument"
    :style="{ width: '80%' }"
    header="Website url"
    :modal="true"
  >
    <div style="display: flex; flex-direction: column; gap: 1rem;">
      <DocumentCrawlerVue
        @getDocumentDataApifyWebsiteContentCrawler="getDocumentDataApifyWebsiteContentCrawler"
        @getDocumentDataApifySmartArticleExtractor="getDocumentDataApifySmartArticleExtractor" 
      />

      <div
        style="display: flex;
        justify-content:center;"
      >
        <Button 
          label="Add Document manually"
          class="p-button-text"
          @click="openCreateDocumentDialog"
        />
      </div>
    </div>
  </Dialog>

  <!-- Delete Document -->
  <Dialog v-model:visible="deleteDocumentDialog" :style="{ width: '450px' }" header="Delete Document" :modal="true">
    <div class="confirmation-content d-flex flex-row">
      <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
      <span>Are you sure you want to delete
        <span class="fw-bold">{{ selectedRowDocument.title }}</span>
      </span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="closeDialog()" />
      <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="deleteDocumentIsLoading"
        :disabled="deleteDocumentIsLoading" @click="deleteDocument()" />
    </template>
  </Dialog>

  <!-- Preview Document  -->
  <Dialog
    v-model:visible= "previewDocumentDialog"
    :style="{width: '80%'}"
    :modal="true" 
  >
    <template #header>
      <h5> Preview <strong>{{selectedRowDocument.title }} </strong> </h5> 
    </template>
    <div v-html="selectedRowDocument.text" ></div>
  </Dialog>
  <!-- Preview Document Hover -->
  <div 
    class="modalPreview"
    v-show="previewHoverDocumentDialog"
  >
    <div class="modalContainer">
        <div v-if="selectedRowDocument">
          <h5>Preview <strong>{{selectedRowDocument.title }} </strong> </h5> 
          <div v-html="selectedRowDocument.text" class="modalContainer-Text" ></div>
        </div>
        <div v-else>
          loading
        </div>
    </div>
  </div>
  <!-- Preview DocumentContract  -->
  <Dialog
    v-model:visible= "previewDocumentContractDialog"
    :style="{width: '80%'}"
    :modal="true"
  >
    <template #header>
      <h5> Contract for:  {{selectedRowDocumentContract.title  }}</h5>
    </template>
    <div>
      {{selectedRowDocumentContract.contract  }}  
    </div>
  </Dialog>
  <!-- preview DocumentContract Hover  -->
  <div 
    class="modalPreview"
    v-show="previewHoverDocumentContractDialog"
  >
    <div class="modalContainer">
        <div v-if="selectedRowDocumentContract">
          <h5>Contract <strong>{{selectedRowDocumentContract.title }} </strong> </h5> 
          <div>
            {{selectedRowDocumentContract.contract }}
          </div>
        </div>
        <div v-else>
          loading
        </div>
    </div>
  </div>
</template>

<script>
import formatToGlobalDate from '@/shared/helpers/date.helper';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import { getProgramsByTopicId } from '../../../../program/services/program.service';
import redirectHelperPage from '../../../../../../shared/helpers/redirectToHelperPage';
// Components
import DocumentCrawlerVue from '../../../../../../components/DocumentCrawler.vue';
// createFormData
import {createFormData} from '@/shared/helpers/formData.helper';
import { createDocument } from '../../../../document/services/document.service';


const toast = useToast();

export default {
  name: 'DocumentsList',
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Dialog,
    Dropdown,
    DocumentCrawlerVue,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filters: null,
      loading: true,

      selectedRowDocument: null,
      selectedRowDocumentContract: null,

      deleteDocumentIsLoading: false,
      deleteDocumentDialog: false,

      previewDocumentDialog:false, 
      previewHoverDocumentDialog:false,

      previewDocumentContractDialog: false,
      previewHoverDocumentContractDialog: false,

      programs : [],
      // addDocument Crawler
      addDocument : false,

      // FilterApplication
      filterApplication: null,

      //filterDocumentSourceType
      filterDocumentSourceType : null,

      //sort 
      sortFieldValue: null,
      sortOrderValue: null,
      //pagination
      firstPagValue: null,
      rowsPagValue: 10,
    };
  },
  validations() {
    return {
    };
  },
  async created() {
    this.initFilters();
    await this.getDocumentApplications();
    await this.getDocumentSources();
    await this.obtainDocumentsByTopicId();
    await this.getProgramsByTopicId();
    this.setTopicDocumentSort();
  },
  computed: {
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    documents() {
      let doc =  this.$store.getters.obtainDocuments;
      if(this.filterApplication){
        if(this.filterApplication === 'applicationNotSet'){
          doc =  doc.filter(d=> d.applications.length === 0 )
        }
        else{
          doc =  doc.filter(d=> d.applications.includes(this.filterApplication) )
        }
      }
      if(this.filterDocumentSourceType){
        doc = doc.filter(d=> d.documentSourceTypeId === this.filterDocumentSourceType)
      }
      return doc;
    },
    documentApplications() {
      const applications = this.$store.getters.obtainDocumentApplications;
      return applications;
    },
    // for filterApplications ,
    documentApplicationsFilter(){
      const applications = this.$store.getters.obtainDocumentApplications;
      const ApplicationFilterOption = [
        {
          label: 'Application',
          items: applications
        },
        {
          label: null ,
          items: [
              {
                  "applicationTypeId": 'applicationNotSet',
                  "name": "Application not set",
              }
          ]
        }
      ]
      return ApplicationFilterOption
    },
    documentSources() {
      return this.$store.getters.obtainDocumentSources;
    },
    // programs() {
    //   let a = []
    //   let c = []
    //   this.documents.map(document => document.topics.map(topic => a.push(topic)))
    //   a.filter(item => item.topicId === this.selectedTopic.topicId)
    //     .map(program => {
    //       const index = c.findIndex(p => p.documentProgramId === program.program.documentProgramId)
    //       if (index === -1) {
    //         c.push(program.program)
    //       }
    //     })
    //   return c
    // },

    topicDocumentSort(){
      const documentSortTopic =this.$store.getters.obtainTopicDocumentSort 
      // console.log(documentSortTopic);
      
      try {
        if(this.selectedTopic.topicId === documentSortTopic.topicId){
          return documentSortTopic
        }
        else{
          const documentSortFromTopicId = {
              sortField: null,
              sortOrder: null,
              firstPagValue: null,
              rowsPagValue: null,
              documentSourceType: null,
              applications:null,
            }
          return documentSortFromTopicId
        }
      } catch (error) {
        const documentSortFromTopicId = {
          sortField: null,
          sortOrder: null,
          firstPagValue: null,
          rowsPagValue: null,
          documentSourceType: null,
          applications:null,
        }
        return documentSortFromTopicId
      }
      // return this.$store.getters.obtainTopicDocumentSort;
    }

  },
  methods: {
    goToHeaderPage(section){
      redirectHelperPage(section)
    },

    async obtainDocumentsByTopicId() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          'obtainDocumentsByTopicId',
          {topicId:this.selectedTopic.topicId, programId: undefined}
        );
        // toast.success('Documents obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getDocumentApplications() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainDocumentApplications');
        // toast.success('Document Applications obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getDocumentSources() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainDocumentSources');
        // toast.success('Document Sources obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getCompanies() {
    },
    async getProgramsByTopicId(){
      try {
        this.loading = true; 
        this.programs = await getProgramsByTopicId( this.selectedTopic.topicId , false)
        this.loading= false;
      } catch (error) {
        this.loading = false ;
      }

    },

    setTopicDocumentSort(){
      try {
        //sort
        this.sortFieldValue = this.topicDocumentSort.sortField;
        this.sortOrderValue = this.topicDocumentSort.sortOrder;

        //pag
        if(this.topicDocumentSort.firstPagValue === null){
          this.firstPagValue = 0;
        }
        else{
          this.firstPagValue = this.topicDocumentSort.firstPagValue;
        }
        if(this.topicDocumentSort.rowsPagValue === null){
          this.rowsPagValue = 10;
        }
        else{
          this.rowsPagValue = this.topicDocumentSort.rowsPagValue
        }

        // applicationFilters
        this.filterDocumentSourceType = this.topicDocumentSort.documentSourceType
        this.filterApplication = this.topicDocumentSort.applications

      } catch (error) {
        console.log(error);
      }
    },

    formatDate(date) {
      if (!date) return '';
      return formatToGlobalDate(date);
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    openAddDocument(){
      this.addDocument = true;
    },
    openCreateDocumentDialog() {
      this.$router.push('./documents/new');
    },
    openEditDocumentDialog(document) {
      this.$router.push(`./documents/edit/${document.documentId}`);
    },
    openPreviewDocumentDialog(document){
      this.previewDocumentDialog = true;
      this.selectedRowDocument = document;
    },
    hoverPreviewDocumentDialogIn(document){
      this.previewHoverDocumentDialog= true;   
      this.selectedRowDocument = document;
      // console.log(document);
    },
    hoverPreviewDocumentDialogOut(){
      this.previewHoverDocumentDialog= null; 
      // this.selectedRowDocument = null;
      // console.log(document);
    },
    openPreviewDocumentContracDialig(document){
      this.previewDocumentContractDialog = true;
      this.selectedRowDocumentContract = document
    },
    hoverPreviewDocumentContractDialogIn(document){
      this.previewHoverDocumentContractDialog= true;   
      this.selectedRowDocumentContract = document;
    },
    hoverPreviewDocumentContractDialogOut(){
      this.previewHoverDocumentContractDialog = null
    },
    openPreviewDocumentFileDialig(document){
      window.open(`${document.filePublicUrl}`, '_blank')
    },
    openDeleteDocumentDialog(document) {
      this.deleteDocumentDialog = true;
      this.selectedRowDocument = document;
    },
    closeDialog() {
      this.addDocument = false; 
      this.deleteDocumentDialog = false;
    },
    async deleteDocument() {
      try {
        this.deleteDocumentIsLoading = true;
        await this.$store.dispatch(
          'removeDocument',
          this.selectedRowDocument.documentId
        );
        this.deleteDocumentIsLoading = false;
        toast.success('Successfully removed.');
        this.closeDialog();
      } catch (error) {
        this.deleteDocumentIsLoading = false;
        toast.error(error.message);
      }
    },
    
    getApplicationNameById(applications) {
      const result = this.documentApplications
        .filter((d) => applications.includes(d.applicationTypeId))
        .map((d) => {
          switch (d.name) {
            case 'Forecast - Production':
              // d.icon = `<i class="pi pi-star-fill" style="font-size: 1.5rem; color: blue;"></i>`;
              // d.icon = `fa-solid fa-industry-windows`;
              // d.prefix = 'fa'
              // d.iconName= 'fa-industry'
              d.prefix = 'fal'
              d.iconName= 'industry-windows'
              break;
            case 'Forecast - Requirement':
              // d.icon = `<i class="pi pi-pencil" style="font-size: 1.5rem; color: black;"></i>`;
              // d.icon = `fa-solid fa-landmark-dome`;
              // d.prefix = 'fa'
              // d.iconName= 'fa-landmark-dome'
              d.prefix = 'fal'
              d.iconName= 'landmark-dome'
              break;
            case 'General Information':
              // d.icon = `<i class="pi pi-sun" style="font-size: 1.5rem; color: #9b188e;"></i>`;
              // d.icon = `fa-solid fa-sun`;
              d.prefix = 'fal'
              d.iconName= 'memo-circle-info'
              break;
            
            case 'Knowledgebase':
              // d.icon = `fa-solid fa-landmark-dome`;
              d.prefix = 'fa'
              d.iconName= 'fa-book'
              break
            case 'Country':
              // d.icon = `<i class="pi pi-flag-fill" style="font-size: 1.5rem; color: green;"></i>`;
              // d.icon = `fa-solid fa-flag`;
              d.prefix= 'fa'
              d.iconName = 'fa-flag'
              break;
            case 'Company':
              // d.icon = `<i class="pi pi-building" style="font-size: 1.5rem; color: red;"></i>`;
              // d.icon = `fa-solid fa-building`;
              d.prefix= 'fa'
              d.iconName = 'fa-building'
              break;
            
            default:
              // d.icon = `<i class="pi pi-check" style="font-size: 1.5rem; color: brown;"></i>`;
              // d.icon = `fa-solid fa-check`;
              d.prefix = 'fa'
              d.iconName= 'fa-check'
              break;
          }
          return d;
        });
      return result;
    },
    
    goToAllDocuments(){
      this.$router.push(`../topicListDocuments`);
    },

    getProgramName( documentProgramIds  ){
      let programArray =[] 
      let programName = [];

      if(this.programs.length > 0){
        documentProgramIds.map(docProgramId => programArray.push( docProgramId.documentProgramId))
        programArray.map( programId => {
          const y = this.programs.filter(program => program.documentProgramId ===programId )
          y.map(yProgram =>{
            programName.push(yProgram.name)
          })
        })
      }
      if(programName.length>0){
        return programName.join(', ')
      }
      else{
        return '--'
      }
    },

    
    // Document contries name for countries Column 
    getDocumentCountriesAbreviation(countries){
      let countriesArray = [] ; 
      countries.map(country => {
        // when render component validate if exits
        if(country.country){
          countriesArray.push(country.country.countryCode)
        }
      })
      if( countriesArray.length > 0 ){
        countriesArray = countriesArray.filter((x,i,a ) => a.indexOf(x)=== i)
        return countriesArray.join(' / ')
      }
      else{
        return '-- '
      }
    },

    // Document Companies name for companies Column 
    getDocumentCompaniesNameAbreviation(companies){
      let companiesArray = [];
      companies.map(company => {
        // when render component validate if exits
        if(company.company){
          //Todo Do Length abreviation 9 letters 

          if(company.company.name.length > 10){
            const abreviationCompany = company.company.name.substring(0, 10) + '..';
            companiesArray.push(abreviationCompany)
          }
          else{
            companiesArray.push(company.company.name)
          }
        }
      })
      if(companiesArray.length > 0){
        companiesArray = companiesArray.filter((x,i,a) => a.indexOf(x) === i)
        return companiesArray.join(', ')
      }
      else{
        return '-- '
      }
    },

    //DocumentSourceTypeName
    getDocumentSourceTypeName(docSourceTypeId){
      const documentSourceType = this.documentSources.filter(item => item.documentSourceId === docSourceTypeId )[0];
      if(documentSourceType){
        return documentSourceType.name    
      }
    },

    // addDocumentCrawler
    // using apifyWebsiteContentCrawler
    async getDocumentDataApifyWebsiteContentCrawler({response}){
      const responseData =  response.data[0]
      if (this.selectedTopic){
        const newDocument = {
          title: responseData.metadata.title,
          // documentSourceTypeId: 6,
          text: responseData.text,
          documentDate : responseData.crawl.loadedTime ,
          documentTopics: [
            {
              topicId: this.selectedTopic.topicId
            }
          ],
          websiteUrl : responseData.url
        }
        await this.createDocument(newDocument); 
      }
      else{
        const newDocument = {
          title: responseData.metadata.title,
          // documentSourceTypeId: 6,
          text: responseData.text,
          documentDate : responseData.crawl.loadedTime,
          websiteUrl : responseData.url
        }
        await this.createDocument(newDocument); 
      }
      this.closeDialog();
    },
    
    // using apifySmartArtcvleExtractor
    async getDocumentDataApifySmartArticleExtractor({response}){
      const responseData = response.data[0] 
      const currentDate = new Date();
      if(this.selectedTopic){
        const newDocument = {
          title: responseData.title,
          // documentSourceTypeId: 6,
          text: responseData.text, 
          documentDate:  (responseData.date)? responseData.date : currentDate,
          documentTopics: [
            {
              topicId: this.selectedTopic.topicId
            }
          ],
          websiteUrl : responseData.url
        } 
        await this.createDocument(newDocument)
      }
      else{
        const newDocument = {
          title: responseData.title,
          // documentSourceTypeId: 6,
          text: responseData.text, 
          documentDate:  (responseData.date)? responseData.date : currentDate,
          websiteUrl : responseData.url
        }
        await this.createDocument(newDocument)
      }
      this.closeDialog();
    },

    // createDocument 
    async createDocument(document){
      try {

        let documentForm = new FormData();

        documentForm.append('title', document.title)
        // documentForm.append('documentSourceTypeId', document.documentSourceTypeId)
        documentForm.append('text', document.text)
        documentForm.append('documentDate', document.documentDate)
        documentForm.append('websiteUrl', document.websiteUrl)

        createFormData(documentForm,'documentTopics' , document.documentTopics)

        const response = await createDocument(documentForm);
        this.openEditDocumentDialog(response.data);

        this.closeDialog();
      } catch (error) {
        console.log(' error', error);
      }
    },

    // sort Functions
    onSort(e){
      const topicDocumentSort = {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        firstPagValue: e.first,
        rowsPagValue: e.rows,
        topicId:this.selectedTopic.topicId,
        documentSourceType: this.filterDocumentSourceType,
        applications : this.filterApplication
      }
      this.$store.dispatch('updateTopicDocumentSort', topicDocumentSort)
    },
    //changeFilters
    onChangeFilters(){
      const  filterDocumentSourceTypes  = {
        topicId : this.selectedTopic.topicId,
        documentSourceType : this.filterDocumentSourceType,
        applications : this.filterApplication
      }

      this.$store.dispatch('updateTopicDocumentSortFilters', filterDocumentSourceTypes)
    }

  },
  watch: {
    async selectedTopic(newValue) {
      if (newValue) {
        await this.obtainDocumentsByTopicId();
      }
    },

    filterDocumentSourceType(){
      this.onChangeFilters();
    },

    filterApplication(){
      this.onChangeFilters();
    }
  },
};
</script>

<style scoped>

.filterApplication{
  padding: 0 3rem 0 0 !important ;
}
.keywordSearch{
   /* padding: 0 0 0 2.5rem  !important; */
   padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
.modalPreview{
  position:fixed;
  z-index: 1101;
  top:0; 
  left: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  pointer-events: none;
}
.modalContainer{
  height: 50%;
  width: 80%;
  overflow: hidden;
  background-color: white;
  margin: 0.75rem;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  /* animation: 0.5s ease-in-out alternate swingViewport ; */
}
@keyframes swingViewport {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
.modalContainer-Text {
  font-size: 1rem;
}

.document-File{
  display: flex; 
  gap: 0.5rem; 
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.document-File:hover{
  color: red;
}

</style>